var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SuperAdminCreatorAccount"},[_c('CircleButton',{attrs:{"label":_vm.$locale['landing_page'],"icon":"lock_1a","size":"56px"},on:{"click":function($event){_vm.Modal({
        title: _vm.$locale['landing_page'],
        class: 'LandingPageCreatorEdit default fullMobile',
        component: function () { return import('./AdminCreatorLanding.vue'); },
        animation: 'fadeIn',
      })}}}),_c('CircleButton',{attrs:{"label":_vm.$locale['profile'],"icon":"user","size":"56px"},on:{"click":function($event){_vm.Modal({
        title: _vm.$locale['profile'],
        class: 'CreatorProfileEdit default fullMobile',
        component: function () { return import('./AdminCreatorProfile.vue'); },
        animation: 'fadeIn',
      })}}}),_c('CircleButton',{attrs:{"label":_vm.$locale['statistics_label'],"icon":"pie_chart","size":"56px"},on:{"click":function($event){return _vm.$router.push({ path: 'admin?admin=statistics-home&view=statistics' })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }